import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaSkype } from "@react-icons/all-files/fa/FaSkype";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";

export const footerData = {
    id: "footer-data",
    section: "footer",
    footer: [
        {
            id: "footer-1",
            list: [
                {
                    id: "footer-list-11",
                    text: "+91-9509000070",
                    link: "tel:+91-9509000070",
                    icon: FaPhoneAlt,
                },
                {
                    id: "footer-list-12",
                    text: "info@woorg.com",
                    link: "mailto:info@woorg.com",
                    icon: FaEnvelope,
                },
                {
                    id: "footer-list-13",
                    text: "B1 Tower, Plot Number-10, Sector-75, Noida India",
                    link: "https://g.page/WoorG",
                    icon: FaMapMarkerAlt,
                },
            ],
        },
        {
            id: "footer-2",
            title: "All Services",
            list: [
                {
                    id: "footer-list-31",
                    text: "Marketing Strategy & Planning",
                    link: "/services/marketing-strategy-planning",
                },
                {
                    id: "footer-list-32",
                    text: "Consumer Insights & Analytics",
                    link: "/services/consumer-insights-analytics",
                },
                {
                    id: "footer-list-33",
                    text: "Video Production & Marketing",
                    link: "/services/video-production-marketing",
                },
                {
                    id: "footer-list-34",
                    text: "Search Engine Optimization",
                    link: "/services/search-engine-optimization",
                },
                {
                    id: "footer-list-35",
                    text: "Web Design & Development",
                    link: "/services/web-design-development",
                },
            ],
        },
        {
            id: "footer-3",
            title: "Information",
            list: [
                {
                    id: "footer-list-21",
                    text: "About Us",
                    link: "/about-us",
                },
                {
                    id: "footer-list-22",
                    text: "Services",
                    link: "/services",
                },
                {
                    id: "footer-list-23",
                    text: "Portfolios",
                    link: "/portfolio",
                },
                {
                    id: "footer-list-24",
                    text: "Blog",
                    link: "/blog",
                },
                {
                    id: "footer-list-25",
                    text: "Contact Us",
                    link: "/contact-us",
                },
            ],
        },
        {
            id: "footer-4",
            title: "Follow Us",
            text: "You talk, We listen. Let's start talking about your plan, rest we will take care.",
            socials: [
                {
                    id: 1,
                    icon: FaFacebookF,
                    link: "https://www.facebook.com/WoorgOfficial",
                    title: "Facebook",
                    color: "#4267B2",
                },
                {
                    id: 2,
                    icon: FaLinkedinIn,
                    link: "https://www.linkedin.com/company/woorgofficial/",
                    title: "linkedin",
                    color: "#0072b1",
                },
                {
                    id: 3,
                    icon: FaSkype,
                    link: "https://www.skype.com",
                    title: "skype",
                    color: "#00aff0",
                },
                {
                    id: 4,
                    icon: FaWhatsapp,
                    link: " https://wa.me/message/YFE6NXM4KNJOI1",
                    title: "whatsapp",
                    color: "#34B7F1",
                },
            ],
        },
        {
            id: "footer-copyright",
            title: "HasThemes",
            link: "https://hasthemes.com/",
        },
    ],
};
