import React, { useState } from "react";
import { Form, Input, NewsLetterBox, ErrMsg } from "./style";
import Button from "../../components/shared/button";
import { useForm } from "react-hook-form";
import axios from "axios";
const NewsLetter = () => {
    const [open, setOpen] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const { email } = data;

        const webhookUrl =
            "https://hooks.slack.com/services/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";

        const postData = {
            text: `Email: ${email}`,
        };

        let res = await axios.post(webhookUrl, JSON.stringify(postData), {
            withCredentials: false,
            transformRequest: [
                (postData, headers) => {
                    delete headers.post["Content-Type"];
                    return postData;
                },
            ],
        });

        if (res.status === 200) {
            reset();
            setOpen(true);
        } else {
            console.log("There was an error.  Please try again later.");
        }
    };
    return (
        <NewsLetterBox>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Input
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        type="email"
                        name="email"
                        {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                    />
                    {errors.email && (
                        <ErrMsg className="text-red">
                            This field is required
                        </ErrMsg>
                    )}
                    <Button
                        type="submit"
                        size="large"
                        shape="rounded10"
                        className="btn-absolute"
                    >
                        Subscribe Now{" "}
                        <i className="icofont-rounded-double-right"></i>
                    </Button>
                </div>
            </Form>
        </NewsLetterBox>
    );
};

export default NewsLetter;
