/** @jsx jsx */
import { jsx } from "theme-ui";
import HeartIcon from "@assets/images/svg/footer.svg";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import TextWidget from "../../components/widget/footer-text-widget";
import ServiceMenuWidget from "../../components/widget/footer-service-menu-widget";
import InformationMenuWidget from "../../components/widget/footer-information-menu-widget";
import FollowUsWidget from "../../components/widget/footer-follow-us-widget";
import { StaticImage } from "gatsby-plugin-image";
import NewsletterWidget from "../../components/widget/newsletter-widget";
import CookieConsent from "react-cookie-consent";
import {
    FooterWrap,
    FooterTop,
    FooterRow,
    FooterCol,
    FooterWidget,
    FooterBottom,
    CopyrightText,
    Link,
} from "./style";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart";

const Footer = ({ data }) => {
    return (
        <FooterWrap>
            <FooterTop>
                <div className="footer-bg-shape">
                    <StaticImage
                        src="../../data/images/footer/Footer-4.png"
                        alt=""
                    />
                </div>
                <div className="path-shape">
                    <StaticImage
                        src="../../data/images/footer/path-shape.png"
                        alt=""
                    />
                </div>
                <div className="path-svg">
                    <HeartIcon />
                </div>
                <Container>
                    <NewsletterWidget />
                    <FooterRow>
                        <FooterCol>
                            <FooterWidget>
                                <TextWidget infoData={data.footer[0]} />
                            </FooterWidget>
                        </FooterCol>
                        <FooterCol>
                            <FooterWidget>
                                <ServiceMenuWidget
                                    allServicesData={data.footer[1]}
                                />
                            </FooterWidget>
                        </FooterCol>
                        <FooterCol>
                            <FooterWidget>
                                <InformationMenuWidget
                                    informationData={data.footer[2]}
                                />
                            </FooterWidget>
                        </FooterCol>
                        <FooterCol>
                            <FooterWidget>
                                <FollowUsWidget followData={data.footer[3]} />
                            </FooterWidget>
                        </FooterCol>
                    </FooterRow>
                </Container>
            </FooterTop>

            <FooterBottom>
                <Container sx={{ textAlign: "center" }}>
                    <CopyrightText>
                        Copyright &copy; {new Date().getFullYear()} Woorg.&nbsp;
                        {/* <FaHeart/> by{" "} */}
                        {/* <a
                            href={data.footer[4].link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {data.footer[4].title}
                        </a>{" "} */}
                        All Rights Reserved
                    </CopyrightText>
                </Container>
            </FooterBottom>
            <CookieConsent
                location="bottom"
                buttonText="I Agree"
                cookieName="teenyfy_consent"
                id="consent-style"
                style={{
                    background:
                        "linear-gradient(to top, #1A4B58 0%, #00CFFF 100%)",
                }}
                buttonStyle={{
                    color: "#1F1F39",
                    fontSize: "16px",
                    background: "white",
                    padding: "10px 50px",
                    "border-radius": "25px",
                    "font-weight": "600",
                }}
                className=""
                expires={150}
            >
                <span style={{ fontSize: "14px", color: "white" }} className="">
                    If you require any more information or have any questions
                    about our{" "}
                    <Link
                        href="/privacy-policy"
                        style={{ color: "white", fontWeight: "bold" }}
                    >
                        Privacy Policy
                    </Link>{" "}
                    for Website and{" "}
                    <Link
                        href="/terms-n-conditions"
                        style={{ color: "white", fontWeight: "bold" }}
                    >
                        Terms of Use
                    </Link>
                    . For more information, please review our{" "}
                    <Link
                        href="/cookie-policy"
                        style={{ color: "white", fontWeight: "bold" }}
                    >
                        Cookie Policy
                    </Link>
                    .
                </span>
            </CookieConsent>
        </FooterWrap>
    );
};
Footer.propTypes = {
    data: PropTypes.shape({
        footer: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            })
        ),
    }),
};
export default Footer;
