import PropTypes from "prop-types";
import FooterWidgetTitle from "../footer-widget-title";
import {
    InformationMenuWidgetBox,
    ServiceUl,
    NavList,
    NavLink,
    NavSpan,
} from "./style";

const InformationMenuWidget = ({ informationData }) => {
    return (
        <InformationMenuWidgetBox>
            <FooterWidgetTitle title={informationData.title} />
            <ServiceUl>
                {informationData.list.map((item) => (
                    <NavList key={item.id}>
                        {item.link !== "/blog" ? (
                            <NavLink href={item.link}>
                                <i className="bi bi-chevron-double-right"></i>
                                {item.text}
                            </NavLink>
                        ) : (
                            <NavSpan>
                                <i className="bi bi-chevron-double-right"></i>
                                {item.text}
                            </NavSpan>
                        )}
                    </NavList>
                ))}
            </ServiceUl>
        </InformationMenuWidgetBox>
    );
};
InformationMenuWidget.propTypes = {
    informationData: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
    }),
};
export default InformationMenuWidget;
