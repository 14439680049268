/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { Fragment } from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import Social, { SocialLink } from "../../shared/social/index";
import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils/mobile-nav-utils";
import {
    MobileMenuContent,
    Navbar,
    NavMunuUl,
    MobileNavitem,
    ButtonClose,
} from "./style";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaSkype } from "@react-icons/all-files/fa/FaSkype";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";

const MobileNavMenu = ({ MobilemenuData, ofcanvasOpen, ofcanvasHandaler }) => {
    const MobileMenuArr = MobilemenuData;

    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 500);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 500);
                }
            });
        }
    };

    return (
        <MobileMenuContent
            className={`${ofcanvasOpen ? "mobile-menu-open" : ""}`}
        >
            <div
                className="OffCanvasContent"
                onClick={ofcanvasHandaler}
                onKeyDown={ofcanvasHandaler}
                role="button"
                tabIndex={0}
            />

            <Navbar className="site-mobile-menu">
                <ButtonClose
                    onClick={ofcanvasHandaler}
                    onKeyDown={ofcanvasHandaler}
                >
                    Close <FaTimes size={24} />
                </ButtonClose>
                <NavMunuUl>
                    {MobileMenuArr.map((menu) => {
                        const submenu = menu.submenu;
                        return (
                            <MobileNavitem
                                key={`menu-${menu.id}`}
                                className={`${
                                    !!submenu ? "has-submenu-dropdown" : ""
                                }`}
                            >
                                {menu.link ? (
                                    <Link
                                        activeClassName="active"
                                        to={menu.link}
                                    >
                                        {menu.text}
                                    </Link>
                                ) : (
                                    <Link
                                        activeClassName="active"
                                        to=""
                                        style={{ pointerEvents: "none" }}
                                    >
                                        {menu.text}
                                    </Link>
                                )}
                                {!!submenu && (
                                    <Fragment>
                                        <button
                                            className="menu-toggle menu-expand"
                                            onClick={onClickHandler}
                                            onKeyDown={onClickHandler}
                                        >
                                            <FaChevronDown />
                                        </button>
                                        <ul className="submenu-nav">
                                            {submenu.map((submenu, i) => {
                                                return (
                                                    <MobileNavitem
                                                        key={`submenu${i}`}
                                                    >
                                                        <Link to={submenu.link}>
                                                            {submenu.text}
                                                        </Link>
                                                    </MobileNavitem>
                                                );
                                            })}
                                        </ul>
                                    </Fragment>
                                )}
                            </MobileNavitem>
                        );
                    })}
                </NavMunuUl>

                <Social sx={{ mt: "30px" }} space={10} size="md" shape="square">
                    <SocialLink href="https://www.facebook.com/">
                        <FaFacebookF />
                    </SocialLink>
                    <SocialLink href="https://twitter.com/home/">
                        <FaTwitter />
                    </SocialLink>
                    <SocialLink href="https://www.skype.com/">
                        <FaSkype />
                    </SocialLink>
                    <SocialLink href="https://www.linkedin.com/">
                        <FaLinkedinIn />
                    </SocialLink>
                </Social>

                {/* <Button
                    sx={{
                        mt: "30px",
                    }}
                    size="large"
                    shape="rounded10"
                    path="/contact-us"
                >
                    Analyze Your Site
                </Button> */}
                {/* <FaArrowRight /> */}
            </Navbar>
        </MobileMenuContent>
    );
};

MobileNavMenu.propTypes = {
    MobilemenuData: PropTypes.array,
    ofcanvasOpen: PropTypes.bool,
    ofcanvasHandaler: PropTypes.func,
};

export default MobileNavMenu;
