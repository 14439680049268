import React from "react";
import { Global, css, device, useTheme } from "@theme/utils";
import slelectimg from "@data/images/icons/arrow-down-white.png";
export const GlobalCSS = () => {
    const theme = useTheme();
    return (
        <Global
            styles={css`
                @import url("https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800&display=swap");
                @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
                @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");

                *,
                *::before,
                *::after {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
                html {
                    -webkit-text-size-adjust: 100%;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    scroll-behavior: smooth;
                    // Default
                    height: 100%;
                    font-size: 70.5%; // 1rem = 6px

                    // Medium Device >=768
                    ${device.medium} {
                        font-size: 73.75%; // 1rem = 7px
                    }

                    // Large Device >=992
                    ${device.large} {
                        font-size: 50%; // 1rem = 8px
                    }

                    // Extra Large Device >=1200
                    ${device.xlarge} {
                        font-size: 56.25%; // 1rem = 9px
                    }

                    // Extra Large Device >=1367
                    ${device.xxlarge} {
                        font-size: 82.5%; // 1rem = 10px
                    }
                }
                article,
                aside,
                details,
                figcaption,
                figure,
                footer,
                header,
                nav,
                section,
                summary {
                    display: block;
                }
                audio,
                canvas,
                video {
                    display: inline-block;
                }
                audio:not([controls]) {
                    display: none;
                    height: 0;
                }
                [hidden] {
                    display: none;
                }
                html,
                button,
                input,
                select,
                textarea {
                    ${device.xsmall} {
                        font-family: "Source Sans Pro", Helvetica, sans-serif;
                    }
                }
                a:focus {
                    outline: thin dotted;
                }
                a:active,
                a:hover {
                    outline: 0;
                }
                b,
                strong {
                    font-weight: bold;
                }
                blockquote,
                q {
                    -webkit-hyphens: none;
                    -moz-hyphens: none;
                    -ms-hyphens: none;
                    hyphens: none;
                    quotes: none;
                }
                small {
                    font-size: smaller;
                }
                sub,
                sup {
                    font-size: 75%;
                    line-height: 0;
                    position: relative;
                    vertical-align: baseline;
                }
                sup {
                    top: -0.5em;
                }
                sub {
                    bottom: -0.25em;
                }
                li > ul,
                li > ol {
                    margin: 0;
                }
                img {
                    -ms-interpolation-mode: bicubic;
                    border: 0;
                    vertical-align: middle;
                }
                svg:not(:root) {
                    overflow: hidden;
                }
                figure {
                    margin: 0;
                }
                form {
                    margin: 0;
                }
                button,
                input,
                select,
                textarea {
                    font-size: 100%;
                    margin: 0;
                    max-width: 100%;
                    vertical-align: baseline;
                }

                button,
                input {
                    line-height: normal;
                }

                button,
                html input[type="button"],
                input[type="reset"],
                input[type="submit"] {
                    -webkit-appearance: button;
                    -moz-appearance: button;
                    appearance: button;
                    cursor: pointer;
                }

                button[disabled],
                input[disabled] {
                    cursor: default;
                }

                input[type="checkbox"],
                input[type="radio"] {
                    padding: 0;
                }

                input[type="search"] {
                    -webkit-appearance: textfield;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    appearance: textfield;
                    padding-right: 2px;
                    width: 100%;
                }

                input[type="search"]::-webkit-search-decoration {
                    -webkit-appearance: none;
                    appearance: none;
                }

                button::-moz-focus-inner,
                input::-moz-focus-inner {
                    border: 0;
                    padding: 0;
                }
                textarea {
                    overflow: auto;
                    vertical-align: top;
                }
                * {
                    box-sizing: border-box;
                }
                img {
                    max-width: 100%;
                }
                html {
                    overflow: hidden;
                    overflow-y: auto;
                }
                body {
                    overflow: hidden;
                    margin: 0;
                    padding: 0;
                    line-height: ${theme.lineHeights.body};
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    ${device.xsmall} {
                        font-family: ${theme.fonts.body};
                    }
                    font-weight: ${theme.fontWeights.body};
                    color: ${theme.colors.text};
                    font-size: ${theme.fontSize.body};
                    background: ${theme.colors.background};
                }
                a {
                    transition: ${theme.transition};
                    color: ${theme.colors.text};
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                        color: ${theme.colors.hover};
                    }
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                .h1,
                .h2,
                .h3,
                .h4,
                .h5,
                .h6 {
                    ${device.xsmall} {
                        font-family: ${theme.fonts.heading};
                    }
                    line-height: ${theme.lineHeights.heading};
                    color: ${theme.colors.heading};
                    font-weight: ${theme.fontWeights.heading};
                    margin: 0;
                }
                h1,
                .h1 {
                    font-size: clamp(32px, 4.1vw, 75px);
                }
                h2,
                .h2 {
                    font-size: clamp(26px, 4.1vw, 55px);
                }
                h3,
                .h3 {
                    font-size: clamp(22px, 2.1vw, 30px);
                }
                h4,
                .h4 {
                    font-size: clamp(20px, 2.1vw, 25px);
                }
                h5,
                .h5 {
                    font-size: clamp(18px, 2.1vw, 22px);
                }
                h6,
                .h6 {
                    font-size: clamp(16px, 3.1vw, 20px);
                }
                p {
                    margin: 0 0 15px;
                    ${device.xsmall} {
                        font-family: ${theme.fonts.body};
                    }
                    color: ${theme.colors.text};
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:only-child {
                        margin-bottom: 0;
                    }
                }
                input,
                button,
                select,
                textarea {
                    background: transparent;
                    border: 1px solid ${theme.colors.boder};
                    transition: ${theme.transition};
                    color: ${theme.colors.text};
                    &:focus,
                    &:active {
                        outline: none;
                        border-color: ${theme.colors.primary};
                    }
                }
                input,
                select,
                textarea {
                    width: 100%;
                }
                input,
                select {
                    height: 40px;
                    padding: 0 15px;
                }
                .form-select {
                    padding: 0px 20px 0 10px;
                    font-size: 16px;
                    max-width: 100%;
                    width: 100%;
                    outline: none;
                    border: none;
                    color: #333;
                    border-radius: 5px;
                    // background: transparent url(${slelectimg}) no-repeat center
                    //     right 5px;
                    background-color: #ddd;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                .wrapper {
                    position: relative;
                }
                .container {
                    max-width: 100%;
                    padding-right: var(--bs-gutter-x, 2.5rem);
                    padding-left: var(--bs-gutter-x, 2.5rem);
                    ${device.medium} {
                        padding-right: var(--bs-gutter-x, 0.9375rem);
                        padding-left: var(--bs-gutter-x, 0.9375rem);
                        // max-width: 720px;
                        &.container-max {
                            max-width: 100%;
                        }
                        .container-max-lg {
                            max-width: 100%;
                        }
                    }
                    ${device.large} {
                        max-width: 960px;
                        &.container-max {
                            max-width: 1370px;
                        }
                        &.container-max-lg {
                            max-width: 1500px;
                        }
                    }
                    ${device.xlarge} {
                        max-width: 1200px;
                    }
                    @media (min-width: 1600px) {
                        &.container-max {
                            max-width: 1370px;
                        }
                    }
                }

                .link-overlay {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    text-indent: -99999px;
                }
                .mbClass-h4 {
                    margin-bottom: 32px;
                }
                .mbClass-p {
                    margin-bottom: 32px !important;
                }
                .mbClass {
                    margin-bottom: 62px;
                }
                .breadcrumb__title {
                    color: #02126a !important;
                }
                .breadcrumb__link {
                    color: #02126a !important;
                }
                .breadcrumb__separator {
                    color: #02126a !important;
                }
                .css-14qo6cj {
                    ${device.large} {
                        height: 149px !important;
                    }
                }
                .css-9pc6ra {
                    margin: 0px 10px !important;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type="number"] {
                    -moz-appearance: textfield;
                }
                .contactus {
                    max-width: 280px;
                }
                .css-vnv2o7-Header {
                    margin-left: 35px !important;
                }
                .css-fs95lx:hover {
                    color: white !important;
                }
                .css-ygnnmk {
                    display: flex !important;
                }
                .text-red {
                    color: red;
                    font-size: 12px;
                    padding: 0px;
                    margin: 0px;
                }
                .modelBox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: auto;
                    position: fixed;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                    outline: none;
                }
                .model2 {
                    position: relative;
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                    width: 84rem;
                }
                .modelBox-child {
                    padding: 20px;
                    background-color: #89cee9;
                    border: none;
                    border-radius: 0.5rem;
                    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
                        0 4px 6px -4px rgb(0 0 0 / 0.1);
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    color: black;
                    outline: none;
                }
                .modelBox-child h3 {
                    color: #02126a;
                    text-align: center;
                    font-size: 1.875rem;
                    font-weight: 500;
                }
                .modeBox-child h3 > span {
                }
                .modelBox-sub-div {
                    position: relative;
                    padding: 0.75rem;
                    flex: 1 1 auto;
                }
                .modelBox-sub-div p {
                    color: #02126a;
                    font-size: 20px;
                    text-align: center;
                    line-height: 1.625;
                }
                .btn {
                    background-color: blue;
                    display: flex;
                    width: 100px;
                    height: 30px;
                    margin: auto;
                    justify-content: center;
                    align-items: center;
                    transition-property: all;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 150ms;
                }
                .modelBox-bottom-div {
                    opacity: 0.25;
                    background: black;
                    z-index: 50;
                }
                .TitleContent {
                    margin-bottom: 0px !important;
                    margin-right: 45px;
                    ${device.mobileview} {
                        margin-right: 0;
                    }
                    ${device.large} {
                        margin-right: 0;
                    }
                }
                .service_inner_desc {
                    font-size: 18px;
                    font-weight: 600;
                }
                .service_inner_desc2 {
                    font-weight: 400;
                }
            `}
        />
    );
};
