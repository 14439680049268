import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useScrollTop } from "@hooks";
import { ScrollTopButton } from "./style";
import { FaArrowUp } from "@react-icons/all-files/fa/FaArrowUp";

const ScrollToTop = ({ className, ...props }) => {
    const stick = useScrollTop();
    const onClickHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    if (stick) {
        return (
            <ScrollTopButton
                type="button"
                className={cn(className, "scroll-to-top")}
                onClick={onClickHandler}
                isStick={true}
                {...props}
            >
                <FaArrowUp className="icon arrow-up" />
                <FaArrowUp className="icon arrow-up" />
            </ScrollTopButton>
        );
    } else {
        return null;
    }
};

ScrollToTop.propTypes = {
    className: PropTypes.string,
};

export default ScrollToTop;
