import {
    jsx,
    css,
    keyframes,
    Global,
    ThemeProvider,
    useTheme,
} from "@emotion/react";
import styled from "@emotion/styled";
import { themeGet } from "@styled-system/theme-get";

const breakpoints = [
    "375",
    "576px",
    "768px",
    "992px",
    "1200px",
    "1400px",
    "1600px",
    "575px",
];

export const device = {
    xsmall: `@media screen and (min-width: ${breakpoints[0]})`,
    small: `@media screen and (min-width: ${breakpoints[1]})`,
    medium: `@media screen and (min-width: ${breakpoints[2]})`,
    large: `@media screen and (min-width: ${breakpoints[3]})`,
    xlarge: `@media screen and (min-width: ${breakpoints[4]})`,
    xxlarge: `@media screen and (min-width: ${breakpoints[5]})`,
    xxxlarge: `@media screen and (min-width: ${breakpoints[6]})`,
    mobileview: `@media screen and (max-width: ${breakpoints[7]})`,
};

export { Global, css, jsx, keyframes, ThemeProvider, themeGet, useTheme };
export * from "styled-system";
export default styled;
